var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ui-table',{attrs:{"columns":_vm.columns,"disabled-columns":_vm.disabledColumns,"rows":_vm.gateways,"render-slices":3},scopedSlots:_vm._u([{key:"path",fn:function(ref){
var value = ref.value;
return [_c('domain-ui-tree-node-path',{attrs:{"tree-nodes":value.items,"context-tree-node":_vm.contextTreeNode},on:{"tree-node-click":function($event){return _vm.$emit('tree-node-click', $event)}}})]}},{key:"serial.label",fn:function(){return [_vm._v("Gateway-ID")]},proxy:true},{key:"serial",fn:function(ref){
var row = ref.row;
return [_c('domain-ui-gateway-info',{attrs:{"gateway":row},on:{"click":function($event){return _vm.$emit('gateway-click', row)}}})]}},{key:"health.label",fn:function(){return [_vm._v("Zustand"),_c('br'),_vm._v("Letzte Aktualisierung")]},proxy:true},{key:"health",fn:function(ref){
var row = ref.row;
return [_c('domain-ui-gateway-health',{attrs:{"health":row.health,"capitalize":""}}),_c('ui-relative-time',{staticClass:"text-light",attrs:{"date":row.latestOkHealthDate,"empty-message":"–","min":"-P1D","format":"L LT"}})]}},{key:"details",fn:function(ref){
var row = ref.row;
return [_c('ui-link',{on:{"click":function($event){return _vm.$emit('gateway-click', row)}}},[_vm._v("Einzelheiten")])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }