



























import { TreeNodePathTreeNode } from '@/features/domain-ui/tree-node-path/model';
import { Column } from '@/features/ui/table/model';
import { ArrayProp, ObjectProp } from '@/util/prop-decorators';
import { Component, Vue } from 'vue-property-decorator';
import { DomainUiGatewayTableGatewayFragment } from './__generated__/DomainUiGatewayTableGatewayFragment';

@Component
export default class GatewayTable extends Vue {
  @ArrayProp(() => [])
  private readonly gateways!: DomainUiGatewayTableGatewayFragment[];

  @ArrayProp(() => [])
  private readonly disabledColumns!: string[];

  @ObjectProp()
  private readonly contextTreeNode?: TreeNodePathTreeNode;

  private readonly columns: Column[] = [
    { name: 'path', label: 'Ort', width: 150 },
    { name: 'serial' },
    { name: 'health' },
    { name: 'details', label: '', align: 'center' },
  ];
}
